
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GridLayout from "../../components/GridLayout"
import PageLayout from "../../components/PageLayout"
import GridTextProjectElement from "../../components/GridTextProjectElement"
import { projectNames } from "../../context/consts"
import Seo from "../../components/Seo"


const cookplayGlovePage = () => {

    const imageComponents = [
        <StaticImage key={1} src="./../../images/cookplay/cookplay-0.jpg" alt="" layout="fullWidth"></StaticImage>,
        <GridTextProjectElement key={2} projectName={projectNames.COOKPLAY} />,
        <StaticImage key={3} src="./../../images/cookplay/cookplay-1.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={4} src="./../../images/cookplay/cookplay-2.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={5} src="./../../images/cookplay/cookplay-3.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={6} src="./../../images/cookplay/cookplay-4.jpg" alt="" layout="fullWidth"></StaticImage>,
    ]

    return (
        <PageLayout firstHead="cookplay" secondHead="rex collection">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="cookplay collection" />
        </PageLayout>
    )

}

export default cookplayGlovePage